import { Carousel } from './carousel';


(function() {

  function whichAnimationEvent(){
    var t,
        el = document.createElement('fakeelement');

    var animations = {
      'animation'      : 'animationend',
      'OAnimation'     : 'oAnimationEnd',
      'MozAnimation'   : 'animationend',
      'WebkitAnimation': 'webkitAnimationEnd'
    }

    for (t in animations){
      if (el.style[t] !== undefined){
        return animations[t];
      }
    }
  }

  // Home

  const carouselHome = $('[data-carousel="home"]');
  const carouselDots = carouselHome.parent().find('.h-carousel__dots-list');
  const animationEvent  = whichAnimationEvent();

  carouselHome.on('init', (event, $slick) => {
    let video = $slick.$slides[0].querySelector('video');
    video.addEventListener('canplaythrough', () => { video.play(); });
  });

  carouselHome.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    let nslide = slick.$slides[nextSlide];
    // let pslide = slick.$slides[currentSlide];

    Array.from(slick.$slider[0].querySelectorAll('.slick-slide')).forEach((el, i) => {
      let video = el.querySelector('video')
      if (el !== nslide) {
        if(video) video.pause();
      } else {
        if(video) video.play();
      }
    })
  });


  const carouselSlick = carouselHome.slick({
    arrows: false,
    dots: true,
    customPaging: function(slider, i) {
      return `<button type="button" aria-title="${i}">
        <span class="main">
          <span class="left"></span>
          <span class="right"></span>
        </span>
      </button>`;
    },
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    appendDots: carouselDots
  });

  carouselDots.on(animationEvent, function(e) {
    if ($(e.target).is('.left')) carouselSlick.slick('slickNext');
  });

  // Our Games

  const carouselBgGames = $('[data-carousel="games-bg"]');
  const carouselGames = $('[data-carousel="games"]');

  carouselBgGames.slick({
    arrows: false,
    fade: true,
    asNavFor: carouselGames,
    responsive: [
      {
        breakpoint: 1024,
        settings: 'unslick'
      }
    ]
  });

  // carouselGames.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
  //   let len = slick.$slides.length;
  //   console.log(len);
  //   console.log(currentSlide, nextSlide);
  // })

  carouselGames.slick({
    asNavFor: carouselBgGames,
    dots: true,
    focusOnSelect: true,
    prevArrow: `<button type="button" class="slick-prev" aria-title="Previous" onclick="dataLayer.push({'event': 'cliqueBannerVoltar'})">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.6 41.6"><path d="M20.8 0c11.5 0 20.8 9.3 20.8 20.8s-9.3 20.8-20.8 20.8S0 32.3 0 20.8 9.3 0 20.8 0zm0 0" fill="#fff"/><path d="M24.6 10.4c.2 0 .4.1.6.2.3.3.3.8 0 1.1l-9 9 9 9c.3.3.3.8 0 1.1-.3.3-.8.3-1.1 0l-9.2-9.2c-.5-.5-.5-1.4 0-1.9l9.2-9.2c.1 0 .3-.1.5-.1zm0 0" fill="#360c6e"/></svg>
    </button>`,
    nextArrow: `<button type="button" class="slick-next" aria-title="Next" onclick="dataLayer.push({'event': 'cliqueBannerAvançar'})">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.6 41.6"><path d="M20.8 41.6C9.3 41.6 0 32.3 0 20.8S9.3 0 20.8 0s20.8 9.3 20.8 20.8-9.3 20.8-20.8 20.8zm0 0" fill="#fff"/><path d="M17 31.2c-.2 0-.4-.1-.6-.2-.3-.3-.3-.8 0-1.1l9-9-9-9c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l9.2 9.2c.5.5.5 1.4 0 1.9L17.6 31c-.2.1-.4.2-.6.2zm0 0" fill="#360c6e"/></svg>
    </button>`,
    responsive: [
      {
        breakpoint: 1024,
        settings: 'unslick'
      }
    ]
  });

  // About

    // Menu

  $('[data-toggle="menu"]').on('click', function() {
    $(document.body).toggleClass('is-nav--open');
  });


  // About Carousel

  const carouselAboutLeft = document.querySelector('[data-carousel="about-left"]');
  const carouselAboutRight = document.querySelector('[data-carousel="about-right"]');
  const carouselButtonBack = document.querySelector('[data-carousel="about-back"]');
  const carouselButtonForward = document.querySelector('[data-carousel="about-forward"]');

  if (carouselAboutLeft && carouselAboutRight) new Carousel(carouselAboutLeft, carouselAboutRight, {buttonBack: carouselButtonBack, buttonForward: carouselButtonForward});
})();

