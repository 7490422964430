function whichTransitionEvent() {
  var el = document.createElement('fakeelement');
  var transitions = {
    'transition':'transitionend',
    'OTransition':'oTransitionEnd',
    'MozTransition':'transitionend',
    'WebkitTransition':'webkitTransitionEnd'
  }

  for(let t in transitions){
      if( el.style[t] !== undefined ){
          return transitions[t];
      }
  }
}

function trigger(eventTarget, eventName, eventData) {
  let event;

  if (!eventTarget || !eventName) return false;
  if (typeof eventData === 'undefined') eventData = {};

  if (window.CustomEvent) {
    event = new CustomEvent(eventName, {detail: eventData, bubbles: true, cancellable: true});
  } else {
    event = document.createEvent('CustomEvent');
    event.initCustomEvent(eventName, true, true, eventData);
  }

  return eventTarget.dispatchEvent(event);
}

function addEventListenerOnce(target, type, listener, addOptions, removeOptions) {
    target.addEventListener(type, function fn(event) {
        target.removeEventListener(type, fn, removeOptions);
        listener.apply(this, arguments, addOptions);
    });
}

export class Carousel {
  constructor(left, right, options={}) {
    const defaults = {

    };


    this._options = Object.assign({}, defaults, options);
    this._leftContainer = left;
    this._rightContainer = right;
    this._leftSteps = Array.from(left.children);
    this._rightSteps = Array.from(right.children);
    this._maxStepsLength = Math.min(this._leftSteps.length, this._rightSteps.length);

    this._currentIndex = 0;

    this._leftSetup = this.calculateSetup(this._leftSteps);
    this._rightSetup = this.calculateSetup(this._rightSteps).reverse();

    this._leftSteps.forEach((el, i) => {
      el.dataset.abIndex = i;
      el.classList.add('ab-carousel__item');
    });

    this._rightSteps.forEach((el, i) => {
      el.dataset.abIndex = i;
      el.classList.add('ab-carousel__item');
    });

    this._leftContainer.classList.add('ab-carousel__container', 'ab-carousel__container--left');
    this._rightContainer.classList.add('ab-carousel__container', 'ab-carousel__container--right');

    this.setSetup(this._leftSetup, this._leftSteps);
    this.setSetup(this._rightSetup, this._rightSteps);

    if (this._options.buttonBack) {
      this._options.buttonBack.addEventListener('click', (e) => {
        e.preventDefault();
        this.moveBackward.call(this)
      });
    }

    if (this._options.buttonForward) {
      this._options.buttonForward.addEventListener('click', (e) => {
        e.preventDefault();
        this.moveForward.call(this)
      });
    }
  }

  setSetup(setup, steps) {
    setup[0].classList.remove('ab-carousel__item--active', 'ab-carousel__item--bottom')
    setup[0].classList.add('ab-carousel__item--top');
    setup[1].classList.remove('ab-carousel__item--top', 'ab-carousel__item--bottom')
    setup[1].classList.add('ab-carousel__item--active');
    setup[2].classList.remove('ab-carousel__item--active', 'ab-carousel__item--top')
    setup[2].classList.add('ab-carousel__item--bottom');

    if (steps) {
      steps.forEach((el) => {
        if (setup.indexOf(el) === -1) el.classList.remove('ab-carousel__item--active', 'ab-carousel__item--bottom', 'ab-carousel__item--top');
      });
    }

    return setup;
  }

  calculateSetup(steps) {
    let i = this._currentIndex;
    let activeStep = steps[i];
    let prevStep, nextStep;

    if (i <= 0) {
      prevStep = steps[steps.length - 1];
    } else {
      prevStep = steps[i - 1];
    }

    if (i >= (steps.length - 1)) {
      nextStep = steps[0];
    } else {
      nextStep = steps[i + 1];
    }

    return [prevStep, activeStep, nextStep];
  }

  afterBackwardMove() {
    this._isTransitioning = false;
    this._leftContainer.classList.add('disable-transitions');
    this._rightContainer.classList.add('disable-transitions');
    this._leftContainer.classList.remove('ab-carousel--backward');
    this._rightContainer.classList.remove('ab-carousel--backward');

    --this._currentIndex;

    if (this._currentIndex < 0) this._currentIndex = (this._maxStepsLength - 1);

    this._leftSetup = this.calculateSetup(this._leftSteps);
    this._rightSetup = this.calculateSetup(this._rightSteps).reverse();

    this.setSetup(this._leftSetup, this._leftSteps);
    this.setSetup(this._rightSetup, this._rightSteps);

    this._leftContainer.offsetHeight && this._rightContainer.offsetHeight;

    this._leftContainer.classList.remove('disable-transitions');
    this._rightContainer.classList.remove('disable-transitions');
  }

  afterForwardMove() {
    this._isTransitioning = false;
    this._leftContainer.classList.add('disable-transitions');
    this._rightContainer.classList.add('disable-transitions');
    this._leftContainer.classList.remove('ab-carousel--forward');
    this._rightContainer.classList.remove('ab-carousel--forward');

    ++this._currentIndex;

    if (this._currentIndex >= this._maxStepsLength) this._currentIndex = 0;

    this._leftSetup = this.calculateSetup(this._leftSteps);
    this._rightSetup = this.calculateSetup(this._rightSteps).reverse();

    this.setSetup(this._leftSetup, this._leftSteps);
    this.setSetup(this._rightSetup, this._rightSteps);

    this._leftContainer.offsetHeight && this._rightContainer.offsetHeight;

    this._leftContainer.classList.remove('disable-transitions');
    this._rightContainer.classList.remove('disable-transitions');
  }

  moveBackward() {
    if(this._isTransitioning) return false;

    this._isTransitioning = true;

    let leftTransitionPromise = new Promise((resolve, reject) => {
      this._leftContainer.classList.add('ab-carousel--backward');
      addEventListenerOnce(this._leftContainer, whichTransitionEvent(), (e) => {
        return resolve(this._leftContainer);
      });
    });

    let rightTransitionPromise = new Promise((resolve, reject) => {
      this._rightContainer.classList.add('ab-carousel--backward');
      addEventListenerOnce(this._rightContainer, whichTransitionEvent(), (e) => {
        return resolve(this._rightContainer);
      });
    });

    Promise.all([leftTransitionPromise, rightTransitionPromise])
      .then(this.afterBackwardMove.bind(this));
  }

  moveForward() {
    if(this._isTransitioning) return false;

    this._isTransitioning = true;

    let leftTransitionPromise = new Promise((resolve, reject) => {
      this._leftContainer.classList.add('ab-carousel--forward');
      addEventListenerOnce(this._leftContainer, whichTransitionEvent(), (e) => {
        return resolve(this._leftContainer);
      });
    });

    let rightTransitionPromise = new Promise((resolve, reject) => {
      this._rightContainer.classList.add('ab-carousel--forward');
      addEventListenerOnce(this._rightContainer, whichTransitionEvent(), (e) => {
        return resolve(this._rightContainer);
      });
    });

    Promise.all([leftTransitionPromise, rightTransitionPromise])
      .then(this.afterForwardMove.bind(this));
  }
}
